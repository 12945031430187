<template>
  <base-layout>
    <div class="bg">
      <div class="content-wrapper">
        <h1>Личный кабинет</h1>
        <tabs-db :links="links">
          <template v-slot:content>
            <table-db :header="history.header" :rows="history.rows" />
          </template>
        </tabs-db>
      </div>
    </div>
  </base-layout>
</template>

<script>
import BaseLayout from "@/layouts/BaseLayout"
import TabsDb from "@/components/TabsDb"
import TableDb from "@/components/TableDb"
import { getReceipts } from "@/api/index"

export default {
  name: "HistoryPersonal",
  components: {
    BaseLayout,
    TabsDb,
    TableDb
  },

  data() {
    return {
      links: [
        {
          name: "Профайл",
          href: "/personal/profile"
        },
        {
          name: "История",
          href: "/personal/history"
        },
        {
          name: "Призы",
          href: "/personal/prizes"
        }
      ],
      history: {
        header: ["№", "Чек", "Кол-во продуктов", "Дата регистрации", "Статус"],
        rows: []
        // rows: [
        //   {
        //     key: 1,
        //     cols: [
        //       {
        //         key: 1,
        //         value: "1"
        //       },
        //       {
        //         key: 2,
        //         value: "899889"
        //       },
        //       {
        //         key: 3,
        //         value: "1"
        //       },
        //       {
        //         key: 4,
        //         value: "10.07.2022"
        //       },
        //       {
        //         key: 5,
        //         value: "принят"
        //       },
        //     ]
        //   }
        // ]
      }
    }
  },

  async mounted() {
    const receipts = await getReceipts();

    if (receipts.error == 1) {
      return
    }

    let counter = 0

    if (receipts.results === [])
    {
      this.history.rows.push({
        key: 1,
        cols: [
          {
            value: ''
          },
          {
            value: ''
          },
          {
            value: ''
          },
          {
            value: ''
          },
          {
            value: ''
          },
        ]
      })
    }

    receipts.result.forEach((item) => {
      this.history.rows.push({
        key: item.id,
        cols: [
          {
            value: ++counter
          },
          {
            value: item.id_for_user
          },
          {
            value: item.countProduct
          },
          {
            value: item.date_create
          },
          {
            value: item.status.text
          },
        ]
      })
    })
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables";

h1 {
  margin-bottom: 45px;
  font-size: 60px;
  text-align: center;
}

.bg {
  position: relative;
  background-color: #fff;
  color: #3F3F3F;

  &::before, &::after {
    content: "";
    position: absolute;
    display: block;
    background-size: cover;
  }

  &::before {
    width: 90px;
    height: 100px;
    top: 60px;
    left: 10%;
    //
  }

  &::after {
    width: 100px;
    height: 95px;
    top: 60px;
    right: 10%;
    //
  }
}

.content-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 45px 0 90px 0;
}

@media screen and (max-width: $lg) {
  h1 {
    font-size: 30px;
  }

  .bg::before, .bg::after {
    background-image: none;
  }

  .content-wrapper {
    padding-bottom: 0;
  }
}
</style>